import React from 'react'
import {Row, Col} from 'react-bootstrap'

import type {NavLink} from '../components/Layout'
import {Header, Footer} from '../components/Layout'
import SEO from '../components/SEO'
import LinkButton from '../components/LinkButton'
import Title from '../components/Title'
import Section from '../components/Section'

import Image404 from '../assets/images/svgs/HPV-404.inline.svg'
import {URL} from '../constants'

const pageCertificate = {
  jobCode: 'GB-NON-05746',
  dateOfPrep: 'March 2022',
}

const NAV_LINKS: NavLink[] = [
  {
    text: 'Information for parents',
    url: URL.HOME,
  },
  {
    text: 'Information for teenagers',
    url: URL.TEENAGERS,
  },
  {text: 'FAQs', url: URL.FAQS},
]

const NotFoundPage = () => {
  return (
    <>
      <SEO title="Page not found." />
      <Header navLinks={NAV_LINKS} />
      <main className="content overflow-hidden">
        <Section id="not-found" className="py-5">
          <Row>
            <Col
              md={6}
              className="d-flex justify-content-center align-items-center mb-5 mb-md-0"
            >
              <Image404 style={{maxWidth: '200px'}} />
            </Col>
            <Col md={6} className="d-flex flex-column">
              <Title color="#000" size="3rem">
                Whoops! <br className="d-xl-none" />
                Page not found.
              </Title>
              <p className="my-5">
                Not to worry. You can head over to our homepage where you can
                find more information.
              </p>
              <LinkButton link="/" className="align-self-md-start">
                Go to homepage
              </LinkButton>
            </Col>
          </Row>
        </Section>
      </main>
      <Footer {...pageCertificate} />
    </>
  )
}
export default NotFoundPage
